import React, { useState } from "react";
import styled from "styled-components";
import Device from "device";
import Media from 'react-media';
import Container from "components/Container";

const Wrap = styled.section`
  width: 100%;
  background: #fff;
  padding: 100px 0 60px;
  position: relative;
  z-index: 2;

  @media ${Device.tablet} {
    padding: 120px 0;
    min-height: 100vh;
  }
  @media ${Device.laptopM} {
    padding: 180px 0;
  }
`;
const Title = styled.h1`
  font-size: 42px;
  line-height: 52px;
  font-weight: 400;
  margin: 0 0 30px 0;
  width: 100%;
`;
const Links = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Link = styled.li`
  margin-bottom: 20px;
`;


const DocumentsContent = () => {

  return (
    <Wrap id="documents">
      <Container>
        <Title>Documents</Title>
        <Links>
          <Link>
            <a href="/i/GBXP-terms-of-business.pdf">Terms of Business</a>
          </Link>
          <Link>
            <a href="/i/GBXP-data-privacy-policy_2.pdf">Data Privacy Policy</a>
          </Link>
        </Links>
      </Container>
    </Wrap>
  );
};

export default DocumentsContent;
