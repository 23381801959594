import React, { useState, useContext, useEffect } from "react"
import styled, { css } from "styled-components"
import Device from "device"
import { Link } from "react-scroll"

import AppContext from "contexts/AppContext"

import Container from "components/Container"
import FormModal from "components/FormModal"

import { ReactComponent as SvgLogo } from "assets/logo.svg"
import { ReactComponent as SvgMenu } from "assets/icon-menu.svg"

import { createHistory } from "@reach/router"

const ToplineWrap = styled.section`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.95);

  @media ${Device.tablet} {
    padding: 24px 0;
  }
`
const LogoLink = styled.a`
  display: block;
  text-decoration: none;
  margin: 0 20px 0 0;
  position: relative;
  z-index: 3;
`
const IconLogo = styled(SvgLogo)`
  width: 103px;
  height: 32px;
`
const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 20px 20px 20px;
  opacity: 0;
  transition: all 0.2s;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  flex-wrap: wrap;
  background: #fff;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  @media ${Device.tablet} {
    flex-wrap: nowrap;
    position: static;
    flex: 1;
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: none;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
`
const MenuActions = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin-top: 20px;

  @media ${Device.tablet} {
    flex-direction: row;
    margin-top: 0;
  }
`
const MenuItemWrap = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    width: auto;
    margin-left: -30px;
  }
`
const MenuItemStyle = css`
  display: inline-block;
  color: ${(props) => props.theme.color.blue};
  text-decoration: none;
  margin: 0 0 24px 0;
  font-size: 24px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  position: relative;

  &:after {
    @media ${Device.laptop} {
      content: "";
      position: absolute;
      top: -39px;
      left: 0;
      right: 0;
      height: 3px;
      background: ${(props) => props.theme.color.red};
      opacity: 0;
      transition: all 0.4s;
    }
  }

  &.active {
    color: ${(props) => props.theme.color.red};

    &:after {
      @media ${Device.laptop} {
        opacity: 1;
      }
    }
  }
  @media ${Device.tablet} {
    margin: 0 0 0 30px;
    line-height: 20px;
    width: auto;
    font-size: 16px;
  }
`
const MenuItemA = styled.a`
  ${MenuItemStyle}
`
const MenuItem = styled(Link)`
  ${MenuItemStyle}
`
const MenuItemBtnCss = css`
  border: none;
  background: ${(props) => props.theme.color.red};
  color: #fff;
  border-radius: 100px;
  font-size: 24px;
  padding: 14px 22px;
  margin: 24px 0 0 0;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  @media ${Device.tablet} {
    padding: 10px 22px;
    margin: 0 0 0 10px;
    font-size: 16px;
  }
  @media ${Device.laptop} {
    margin: 0 0 0 30px;
  }
`
const MenuItemBtn = styled.button`
  ${MenuItemBtnCss}
`
const MenuItemBtnLink = styled.a`
  ${MenuItemBtnCss}
`
const ToggleMenu = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 3;
  position: relative;
  background: none;
  appearance: none;
  color: ${(props) => props.theme.color.blue};

  @media ${Device.tablet} {
    display: none;
  }
`
const IconMenu = styled(SvgMenu)`
  width: 55px;
  height: 40px;
  stroke: currentColor;
`
const LoginLink = styled.a`
  ${MenuItemBtnCss}
  background: #fff;
  color: ${(props) => props.theme.color.red};
  border: 2px solid ${(props) => props.theme.color.red};
  transition: all 0.3s ease-in-out;
  padding-top: 12px;
  padding-bottom: 12px;

  @media ${Device.tablet} {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &:hover,
  &:focus {
    background: ${(props) => props.theme.color.red};
    color: #fff;
  }
`

const ToAccount = styled.a`
  ${MenuItemBtnCss}
  background: #fff;
  color: ${(props) => props.theme.color.red};
  border: 2px solid ${(props) => props.theme.color.red};
  transition: all 0.4s ease-in-out;
  padding-top: 12px;
  padding-bottom: 12px;

  @media ${Device.tablet} {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &:hover,
  &:focus {
    background: ${(props) => props.theme.color.red};
    color: #fff;
  }
`

const Topline = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const url = createHistory(window)

  const { setModal, user } = useContext(AppContext)

  function handleToggleMenu() {
    setIsMenuOpened(isMenuOpened === true ? false : true)
  }
  function handleMenuClick() {
    setIsMenuOpened(false)
  }

  return (
    <ToplineWrap>
      <Container>
        <LogoLink href="/">
          <IconLogo />
        </LogoLink>
        <Menu className={isMenuOpened ? "open" : null}>
          <MenuItemWrap>
            {url.location.pathname === "/" ? (
              <MenuItem
                to="solutions"
                spy={true}
                activeClass="active"
                onClick={handleMenuClick}
              >
                Our Solutions
              </MenuItem>
            ) : (
              <MenuItemA href="/#solutions">Our Solutions</MenuItemA>
            )}
            {url.location.pathname === "/" ? (
              <MenuItem
                to="prepaid"
                spy={true}
                activeClass="active"
                onClick={handleMenuClick}
              >
                Prepaid Card
              </MenuItem>
            ) : (
              <MenuItemA href="/#prepaid">Prepaid Card</MenuItemA>
            )}
            {url.location.pathname.indexOf("fees") > 0 ||
            url.location.hash.indexOf("fees") > 0 ? (
              <MenuItem
                to="fees"
                spy={true}
                activeClass="active"
                onClick={handleMenuClick}
              >
                Fees
              </MenuItem>
            ) : (
              <MenuItemA href="/#fees">Fees</MenuItemA>
            )}
            {url.location.pathname === "/" ? (
              <MenuItem
                to="about"
                spy={true}
                activeClass="active"
                onClick={handleMenuClick}
              >
                About us
              </MenuItem>
            ) : (
              <MenuItemA href="/#about">About us</MenuItemA>
            )}
            {url.location.pathname === "/" ? (
              <MenuItem
                to="contacts"
                spy={true}
                activeClass="active"
                onClick={handleMenuClick}
              >
                Contacts
              </MenuItem>
            ) : (
              <MenuItemA href="/#contacts">Contacts</MenuItemA>
            )}
          </MenuItemWrap>
          {user.isAuthenticated && user.isAuthenticated !== "false" ? (
            <React.Fragment>
              <ToAccount href="/clientsarea/account/">My account</ToAccount>
              <MenuItemBtnLink
                href="/clientsarea/logout/"
                title={
                  user.email ? "" + user.name + ": " + user.email : "logout"
                }
              >
                Logout
              </MenuItemBtnLink>
            </React.Fragment>
          ) : (
            <MenuActions>
              <MenuItemBtn
                type="button"
                onClick={() => setModal(<FormModal />)}
              >
                Open an account
              </MenuItemBtn>
              <LoginLink
                className="login-btn"
                href="/clientsarea/accounts/login/"
              >
                Log&nbsp;in
              </LoginLink>
              {/* <LoginLink type="button" className="login-btn" onClick={() => {
                  setModal(<FormModal type="login" />);
              }}>
                Log&nbsp;in
              </LoginLink> */}
            </MenuActions>
          )}
        </Menu>
        <ToggleMenu
          onClick={handleToggleMenu}
          className={isMenuOpened ? "cross" : null}
        >
          <IconMenu />
        </ToggleMenu>
      </Container>
    </ToplineWrap>
  )
}

export default Topline
