import React, { useState } from "react"
import styled from "styled-components"
import Device from "device"
import Media from "react-media"
import Container from "components/Container"

const Wrap = styled.section`
  width: 100%;
  background: #fff;
  padding: 100px 0 60px;
  position: relative;
  z-index: 2;

  @media ${Device.tablet} {
    padding: 120px 0;
    min-height: 100vh;
  }
  @media ${Device.laptopM} {
    padding: 180px 0;
  }
`
const NavWrap = styled.div`
  position: relative;
  width: 100%;
`
const Nav = styled.nav`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  @media ${Device.tablet} {
    position: relative;
    margin: 0 0 48px 0;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    height: auto;
  }
`
const NavItem = styled.h3`
  cursor: pointer;
  color: rgba(0, 35, 125, 0.2);
  font-size: 32pxpx;
  font-weight: normal;
  line-height: 32px;
  margin: 0;
  position: relative;
  display: block;
  width: 100%;
  margin: 10px 0;
  text-align: center;

  @media ${Device.tablet} {
    font-size: 56px;
    line-height: 70px;
    margin: 0 64px 0 0;
    display: inline-block;
    width: auto;
    text-align: left;
  }

  &.active {
    color: ${(props) => props.theme.color.blue};
  }
  &[disabled] {
    display: none;
    span {
      position: relative;
      &:after {
        content: "soon";
        position: absolute;
        top: -10px;
        right: -20px;
        color: ${(props) => props.theme.color.blue};
        font-size: 12px;
        line-height: 12px;
        font-weight: 800;
      }
    }
  }
`
const CurrentTab = styled.div`
  font-size: 36px;
  color: ${(props) => props.theme.color.blue};
  position: relative;
  display: inline-block;

  @media ${Device.tablet} {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -20px;
    width: 0;
    height: 0;
    border: 6px solid rgba(255, 255, 255, 0);
    border-top: 6px solid ${(props) => props.theme.color.blue};
    margin-top: -3px;
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
`
const Table = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 40px;
  &.hidden {
    display: none;
  }
`
const Row = styled.div`
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  &.hidden {
    display: none;
  }
`
const HeaderRow = styled(Row)`
  font-weight: 800;
  border-bottom: 1px solid ${(props) => props.theme.color.blue};
`
const Col = styled.div`
  width: 100%;
  margin: 0;
  padding: 15px 0;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid rgba(0, 35, 125, 0.2);

  &.twohalves {
    max-width: calc(50% + 30px) !important;
    @media ${Device.mobile} {
      max-width: 100% !important;
    }
  }

  .note {
    font-size: 12px;
    line-height: 15px;
    display: block;
    max-width: 215px;
    margin-top: 6px;
  }

  @media ${Device.tablet} {
    margin: 0 0 0 30px;
  }

  &:nth-child(1) {
    width: 100%;
    border-bottom: 0;

    @media ${Device.tablet} {
      flex: 1;
      margin: 0;
      width: auto;
      border-bottom: 1px solid rgba(0, 35, 125, 0.2);
    }
  }
  &:nth-child(2),
  &:nth-child(3) {
    max-width: 50%;

    @media ${Device.tablet} {
      max-width: 25%;
    }
  }
`
const HeaderCol = styled(Col)`
  border-bottom: 0;
  &:nth-child(1) {
    color: ${(props) => props.theme.color.blue};
    border-bottom: none;
  }
  &:nth-child(2),
  &:nth-child(3) {
    @media ${Device.mobile} {
      display: none;
    }
  }
`
const HeaderRowMobile = styled.div`
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: bold;
`
const HeaderColMobile = styled.div`
  width: 100%;
  max-width: 50%;
  margin: 0;
  padding: 15px 0;
  font-size: 16px;
  line-height: 24px;
`
const Link = styled.a`
  display: block;
  width: 100%;
  margin-bottom: 20px;
  color: ${(props) => props.theme.color.blue};
  font-weight: 800;
  &.hidden {
    display: none;
  }
`
const Disclaimer = styled.p``
const ComingSoon = styled.div`
  font-size: 32px;
  color: ${(props) => props.theme.color.blue};
  display: block;
  margin-bottom: 60px;
  &.hidden {
    display: none;
  }
`

// const tabs = [
//   {
//     id: "tier1",
//     name: "Tier1",
//     disabled: false,
//   },
//   {
//     id: "tier2",
//     name: "Tier2",
//     disabled: false,
//   },
//   {
//     id: "tier3",
//     name: "Tier3",
//     disabled: false,
//   },
// ]

const MobileTitles = () => {
  return (
    <Media
      queries={{
        tablet: "(max-width: 767px)",
      }}
    >
      {(matches) =>
        matches.tablet ? (
          <HeaderRowMobile>
            <HeaderColMobile>Individual</HeaderColMobile>
            <HeaderColMobile>Corporate</HeaderColMobile>
          </HeaderRowMobile>
        ) : null
      }
    </Media>
  )
}

const Fees = () => {
  const [tab, setTab] = useState("tier1")
  const [tabOpen, setTabOpen] = useState(false)
  const [tabName, setTabName] = useState("EU/EEA")

  function handleUpdateTab(e) {
    if (e.disabled) {
      setTabOpen(false)
      return
    }
    setTab(e.id)
    setTabName(e.name)
    setTabOpen(false)
  }
  function handleTabOpen(e) {
    setTabOpen(tabOpen ? false : true)
  }

  return (
    <Wrap id="fees">
      <Container>
        {/* <NavWrap>
          <CurrentTab onClick={() => handleTabOpen()}>{tabName}</CurrentTab>
          <Nav className={tabOpen ? "active" : null}>
            {Object.keys(tabs).map((key) => (
              <NavItem
                key={key}
                onClick={() => handleUpdateTab(tabs[key])}
                className={tab === tabs[key].id ? "active" : null}
                disabled={tabs[key].disabled}
              >
                <span>{tabs[key].name}</span>
              </NavItem>
            ))}
          </Nav>
        </NavWrap> */}

        <Table>
          <HeaderRow>
            <HeaderCol>Payment Account Fees</HeaderCol>
            <HeaderCol>Individual (GBP / EUR)</HeaderCol>
            <HeaderCol>Corporate (GBP / EUR)</HeaderCol>
          </HeaderRow>
          <MobileTitles />
          <Row>
            <Col>One-Time Account Opening Fee</Col>
            <Col>25</Col>
            <Col>500</Col>
          </Row>
          <Row>
            <Col>Account Opening with Enhanced Due Diligence</Col>
            <Col>250</Col>
            <Col>1,000</Col>
          </Row>
          <Row>
            <Col>Monthly Account Management Fee</Col>
            <Col>Free</Col>
            <Col>100</Col>
          </Row>
          <HeaderRow>
            <HeaderCol>Transaction Fees</HeaderCol>
            <HeaderCol>Individual (GBP / EUR)</HeaderCol>
            <HeaderCol>Corporate (GBP / EUR)</HeaderCol>
          </HeaderRow>
          <MobileTitles />
          <Row>
            <Col>Transfers In (Deposit)</Col>
            <Col>Free</Col>
            <Col>Free</Col>
          </Row>
          <Row>
            <Col>
              Transfer Out (Withdrawal)<br></br>
              <span className="note">
                another GBXP or EXANTE trading account
              </span>
            </Col>
            <Col>Free</Col>
            <Col>
              0.1% / transaction<br></br>
              <span className="note">min £/€10 max £/€150</span>
            </Col>
          </Row>
          <Row>
            <Col>
              Transfer Out (Withdrawal)<br></br>
              <span className="note">
                outside GBXP, anything other than SWIFT
              </span>
            </Col>
            <Col>
              0.10% / transaction<br></br>
              <span className="note">max £/€75</span>
            </Col>
            <Col>
              0.25% / transaction<br></br>
              <span className="note">min £/€10 max £/€150</span>
            </Col>
          </Row>
          <Row>
            <Col>
              Transfer Out (Withdrawal)<br></br>
              <span className="note">via SWIFT</span>
            </Col>
            <Col>
              0.25% / transaction<br></br>
              <span className="note">min £/€30 max £/€75</span>
            </Col>
            <Col>
              0.5% / transaction<br></br>
              <span className="note">min £/€30 – max £/€150</span>
            </Col>
          </Row>
          <Row>
            <Col>Correction, Cancellation, Investigation</Col>
            <Col>125</Col>
            <Col>125</Col>
          </Row>
          <Row>
            <Col>Express Transfer Fee</Col>
            <Col>40</Col>
            <Col>40</Col>
          </Row>
          <HeaderRow>
            <HeaderCol>Card Fees</HeaderCol>
            <HeaderCol>Individual (GBP / EUR)</HeaderCol>
            <HeaderCol>Corporate (GBP / EUR)</HeaderCol>
          </HeaderRow>
          <MobileTitles />
          <Row>
            <Col>New Standard GBXP Card</Col>
            <Col>Free</Col>
            <Col>Free</Col>
          </Row>
          <Row>
            <Col>
              Replacement / Additional Standard GBXP Card<br></br>
              <span className="note">Per Card</span>
            </Col>
            <Col>10</Col>
            <Col>20</Col>
          </Row>
          <Row>
            <Col>
              Standard GBXP Card Monthly Management Fee<br></br>
              <span className="note">Per Card</span>
            </Col>
            <Col>Free</Col>
            <Col>30</Col>
          </Row>
          <Row>
            <Col>Auto-Debit from the EXANTE Trading Account</Col>
            <Col>Free</Col>
            <Col>Free</Col>
          </Row>
          <Row>
            <Col>ATM Withdrawal</Col>
            <Col>
              2.0% / transaction<br></br>
              <span className="note">
                or min £/€1 (whichever is higher); first&nbsp;£/€1,000 per month
                free of charge
              </span>
            </Col>
            <Col>
              2.0% / transaction<br></br>
              <span className="note">or min £/€2 (whichever is higher)</span>
            </Col>
          </Row>
        </Table>
        {/* <Link href="/cardlimits">Card information</Link>

        <Disclaimer>
          Fees can be increased based on customer profile, customer business
          activity or status. <br></br>You will be informed about the increased
          fees (if any) during the registration process or prior the changes in
          fees applicable to You.
        </Disclaimer> */}
      </Container>
    </Wrap>
  )
}

export default Fees
