import React, { Fragment, useContext } from "react"
import styled from "styled-components"

import Topline from "components/Topline"
import Intro from "components/Intro"
import Personal from "components/Personal"
import PrepaidCard from "components/PrepaidCard"
import FeesSection from "components/FeesSection"
import About from "components/About"
import Contacts from "components/Contacts"
import Footer from "components/Footer"
import FormModal from "components/FormModal"
import AppContext from "contexts/AppContext"

const Main = styled.main`
  width: 100%;
`

const Homepage = () => {
  const { setModal } = useContext(AppContext)

  return (
    <Fragment>
      <Topline />
      <Main
        onLoad={() =>
          document.referrer.indexOf("cards") > 0
            ? setModal(<FormModal type="login" />)
            : null
        }
      >
        <Intro />
        <Personal />
        <PrepaidCard />
        <FeesSection />
        <About />
        <Contacts />
        <Footer />
      </Main>
    </Fragment>
  )
}
export default Homepage
