const Theme = {
  color: {
    blue: "#00237d",
    red: "#dc1428"
  },
  width: {
    container: "1170px"
  }
};

export default Theme;
