import React, { Fragment } from "react";
import styled from "styled-components";

import Topline from "components/Topline";
import Footer from "components/Footer";

const Main = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
`;
const Section = styled.section`
  padding: 120px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h1`
  margin: 0;
`;
const NotFound = () => {
  return (
    <Fragment>
      <Topline />
      <Main>
        <Section>
          <Title>Page Not Found</Title>
        </Section>
        <Footer />
      </Main>
    </Fragment>
  );
};
export default NotFound;
