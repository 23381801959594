import React, { Fragment } from "react";
import styled from "styled-components";

import Topline from "components/Topline";
import DocumentsContent from "components/Documents/DocumentsContent";
import Footer from "components/Footer";

const Main = styled.main`
  width: 100%;
`;

const Documents = () => {
  return (
    <Fragment>
      <Topline />
      <Main>
        <DocumentsContent />
        <Footer />
      </Main>
    </Fragment>
  );
};
export default Documents;
