import React, { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import Device from "device";
import AppContext from "contexts/AppContext";
import Container from "components/Container";
import FormModal from "components/FormModal";

const IntroWrap = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 100px 0 60px 0;
  background-image: url("/i/intro/intro-02-red.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media ${Device.retina} {
    background-image: url("/i/intro/intro-02-red@2x.jpg");
  }
  @media ${Device.tablet} {
    padding: 200px 0 100px 0;
  }

  &:before {
    content: "";
    background: linear-gradient(to right, #183ac5, #ca0517);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }
`;
const levi = keyframes`
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 30px 0;

  @media ${Device.tablet} {
    flex-wrap: nowrap;
  }
`;
const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media ${Device.tablet} {
    flex-wrap: nowrap;
  }
`;
const Slogan = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 20px 0;
  width: 100%;

  @media ${Device.tablet} {
    max-width: 430px;
    margin: 0;
  }
`;

const Card = styled.div`
  width: 100%;
  text-align: center;

  @media ${Device.tablet} {
    flex: 1;
    width: auto;
  }
`;
const CardImg = styled.img`
  max-width: 100%;
  animation-name: ${levi};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  filter: drop-shadow(-10px 30px 10px rgba(0,0,0,.2));
`;
const Title = styled.h1`
  font-size: 42px;
  line-height: 52px;
  font-weight: 400;
  margin: 0 0 30px 0;
  width: 100%;

  span {
    font-weight: 800;
  }

  @media ${Device.tablet} {
    font-size: 72px;
    line-height: 90px;
    margin: 75px 0 90px 0;
    max-width: 430px;
  }
`;
const BtnWrap = styled.div`
  width: 100%;
  text-align: center;

  @media ${Device.tablet} {
    flex: 1;
    width: auto;
  }
`;
const BtnCss = css`
  width: 100%;
  background: #fff;
  color: #000;
  line-height: 30px;
  font-size: 24px;
  padding: 15px 0;
  border-radius: 100px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  margin: 0 auto;
  border: none;
  cursor: pointer;

  @media ${Device.tablet} {
    padding: 15px 33px;
    display: inline-block;
    width: auto;
  }
`;
const Btn = styled.button`
  ${BtnCss}
`;

const Intro = () => {
  const { setModal, user } = useContext(AppContext);

  return (
    <IntroWrap id="solutions">
      <Container className="justify-start">
        <Top>
          <Title>
            Your finance <br />
            made <span>easy</span>
          </Title>
          <Card>
            <CardImg
              src="/i/intro/gbxp-card-intro.png"
              srcSet="/i/intro/gbxp-card-intro@2x.png 2x"
              alt="Card"
            />
          </Card>
        </Top>
        <Footer>
          <Slogan>
            It’s no bank, <br />
            it’s something better!
          </Slogan>
          <BtnWrap>
            {!user.isAuthenticated && user.isAuthenticated !== "false" ? (
              <Btn onClick={() => setModal(<FormModal />)}>
                Manage your finance
              </Btn>
            ) : (
              null
            )}
          </BtnWrap>
        </Footer>
      </Container>
    </IntroWrap>
  );
};

export default Intro;
