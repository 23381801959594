import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import Device from "device"
import axios from "axios"
import Countdown from "react-countdown"

import AppContext from "contexts/AppContext"

import { Modal } from "components/Modal"
import ButtonStyle from "components/ButtonStyle"

import "react-phone-number-input/style.css"
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input"

import { ReactComponent as SvgClose } from "assets/icon-close.svg"

const Title = styled.h2`
  margin: 0;
  font-size: 24px;

  @media ${Device.tablet} {
    font-size: 56px;
  }
`
const Note = styled.p`
  margin-bottom: 24px;
`
const TabList = styled.nav`
  width: 100%;
  margin: 5px 0 0 0;

  @media ${Device.tablet} {
    margin: 30px 0 0 0;
  }
`
const TabTitle = styled.h3`
  display: inline-block;
  margin: 0 24px 0 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: ${(props) => props.theme.color.blue};
  cursor: pointer;
  opacity: 0.4;

  @media ${Device.tablet} {
    font-size: 24px;
    line-height: 30px;
    margin: 0 30px 0 0;
  }

  &.active {
    opacity: 1;
  }
`
const Form = styled.form``
const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  transition: all 0.2s;
  cursor: pointer;

  @media ${Device.tablet} {
    padding: 10px 0;
    font-size: 16px;
  }
`
const LabelTop = css`
  font-size: 12px;
  transform: translateY(-15px);
  background: rgba(255, 255, 255, 0);
  opacity: 0.4;
  padding: 5px 0;
`
const Input = styled.input`
  &:focus {
    & + label {
      ${LabelTop}
    }
  }
`
const FieldSetWrap = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  &.field-code {
    justify-content: flex-start;
    align-items: center;
  }
`
const FieldSet = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;

  @media ${Device.tablet} {
    max-width: 47%;
    margin: 0 0 20px 0;
  }

  &.fullwidth {
    @media ${Device.tablet} {
      max-width: 100%;
    }
  }

  &.filled {
    label {
      ${LabelTop}
    }
  }

  &.code {
    max-width: 150px;
    margin-right: 14px;
    margin-bottom: 5px;
  }
`
const Header = styled.header`
  padding: 20px;

  @media ${Device.tablet} {
    padding: 30px;
  }
`
const Body = styled.div`
  padding: 0 20px 10px 20px;

  @media ${Device.tablet} {
    padding: 0 30px 30px 30px;
  }
`
const Footer = styled.footer`
  padding: 20px;
  background: rgba(0, 35, 125, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;

  @media ${Device.tablet} {
    padding: 30px;
    text-align: left;
  }
`
const Submit = styled.button`
  ${ButtonStyle}
  width: 100%;

  @media ${Device.tablet} {
    width: auto;
  }
`
const Close = styled.button`
  border: none;
  position: absolute;
  background: none;
  top: 30px;
  right: 30px;
  padding: none;
  margin: 0;
  cursor: pointer;
  appearance: none;
  color: ${(props) => props.theme.color.blue};

  svg {
    width: 16px;
    height: 16px;
    stroke: currentColor;
  }
`
const FooterText = styled.div`
  line-height: 24px;
  margin: 15px 0 0 0;
  width: 100%;

  @media ${Device.tablet} {
    margin: 0;
    width: auto;
  }
`
const LoginLink = styled.a`
  color: ${(props) => props.theme.color.red};
  font-weight: 800;
  cursor: pointer;
`
const Reset = styled.a`
  color: ${(props) => props.theme.color.red};
  cursor: pointer;
  margin: 15px 0 0 0;
`
const Rotate = keyframes`
0% {
  transform: rotate(0);
  opacity: 1;
}
50% {opacity: .5;}
100% {
  transform: rotate(360deg);
  opacity: 1;
}
`
const Loading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  cursor: wait;

  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0);
    border-top: 4px solid ${(props) => props.theme.color.blue};
    border-radius: 100px;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    animation-name: ${Rotate};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
`
const Error = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.color.red};
`
const InputError = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.color.red};
`
const PhoneHiddenInput = styled.input`
  padding: 0 !important;
  border: 0 !important;
  height: 1px;
  opacity: 0;
  pointer-events: none;
`
const ResendButton = styled.button`
  font-size: 16px;
  background: none;
  padding: 10px;
  border: 1px solid #adacac;
  cursor: pointer;
`

const FormModal = (props) => {
  const types = ["login", "registration", "reset"]
  const mainSubmit = useRef(null)
  const { setModal, country, user } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState(
    types.includes(props.type) ? props.type : "registration"
  )
  const [tab, setTab] = useState("personal")
  const [fields, setFields] = useState({
    full_name: "",
    email: "",
    phone: "",
    country_code: "",
    company: "",
    login: "",
    password: "",
  })
  const [settings, setSettings] = useState({
    stage: "",
    code: "",
    codeInvalid: false,
    formErrorTextDefaultText: "Something went wrong, try again later.",
    formErrorText: "",
    mfa_type: "",
    key: "", // for mfa
    tokenid: "", // key + tokenid for resend
  })
  const action = {
    login: "/clientsarea/rest/signin-v2/",
    resend: "/clientsarea/rest/mfa/resend/",
    password_recovery: "/clientsarea/account/password_recovery/",
    registration: "/clientsarea/rest/signup/",
  }
  const errorsInitialState = {
    login: null,
    email: null,
    phone: null,
    fullphone: null,
    full_name: null,
    non_field_errors: null,
    country_code: null,
    password: null,
  }
  const [errors, setErrors] = useState(errorsInitialState)
  const [key, setKey] = useState(0)

  function countRestart() {
    setSettings({
      ...settings,
      stage: "2fa",
    })
    resend(settings.tokenid)
    setKey(key + 1)
  }

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <ResendButton onClick={countRestart}>Resend</ResendButton>
    } else {
      // Render a countdown
      return <span>You may resend new code after {seconds} seconds</span>
    }
  }

  function updFields(e) {
    setErrors(errorsInitialState)
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }
  function keyPress(e) {
    if (e.key === "Escape") {
      setModal(null)
    }
    if (e.key === "Enter") {
      mainSubmit.current.click()
    }
  }
  function handleUpdPhone(value) {
    if (!value) {
      return
    }

    const parsedPhoneNumber = parsePhoneNumber(value)

    if (!parsedPhoneNumber) {
      setErrors((errors) => ({ ...errors, phone: "Invalid phone number" }))
      return
    }

    setErrors(errorsInitialState)
    setFields({
      ...fields,
      phone: parsedPhoneNumber.nationalNumber,
      country_code: parsedPhoneNumber.countryCallingCode,
      fullphone: value,
    })
  }
  function switchType(t) {
    submitError("")
    setType(t)
    setErrors(errorsInitialState)
  }
  function submitError(txt) {
    setSettings((settings) => ({
      ...settings,
      formErrorText: txt || "",
    }))

    if (settings.stage == "2fa") {
      settings.codeInvalid = true
    }
  }
  function requestErrorResult(error) {
    console.log(error)
    setIsLoading(false)
    if (error.status === 400) {
      return Object.keys(error.data).map((item, key) => {
        setErrors((errors) => ({ ...errors, [item]: error.data[item] }))
      })
    }
    if (error.status === 404) {
      return submitError(settings.formErrorTextDefaultText)
    }
    if (error.status === 429) {
      return submitError(
        "Too much login attempts! Logging in is locked for few hours."
      )
    } else {
      submitError()
    }
  }
  function serialize(data) {
    let flds = ""
    for (var item of Object.entries(data)) {
      if (item[1] && item[0] != "fullphone") {
        flds +=
          encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1]) + "&"
      }
    }
    return flds
  }
  function login(logindata) {
    if (settings.stage !== "2fa") {
      axios
        .request({
          method: "post",
          url: action.login,
          data: serialize(logindata),
          withCredentials: true,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
        .then((response) => {
          if (response.data.stage === "done") {
            window.location.href = response.data.url
          } else if (response.data.stage === "2fa") {
            setSettings({
              ...settings,
              stage: response.data.stage,
              mfa_type: response.data.mfa_type,
              key: response.data.key,
              tokenid: response.data.tokenid,
              codeInvalid: false,
              submitStatus: "",
            })

            setIsLoading(false)
            return false
          } else {
            settings.submitStatus = ""
            return submitError()
          }
        })
        .catch((error) => {
          console.log(error)
          requestErrorResult(error.response)
        })
    } else {
      setIsLoading(true)
      smsCheck()
    }
  }
  function smsCheck() {
    const key = settings.key
    const code = document.querySelector("#code").value

    axios
      .request({
        method: "post",
        url: action.login,
        data: `code2fa=${code}&key=${key}&`,
        withCredentials: true,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
      .then((response) => {
        setIsLoading(true)
        if (response.data.stage === "done") {
          window.location.href = response.data.url
        } else {
          settings.submitStatus = ""
          return submitError()
        }
      })
      .catch((error) => {
        console.log(error)
        requestErrorResult(error.response)
      })
  }
  function resend(resenddata) {
    axios
      .request({
        data: `tokenid=${resenddata}&key=${settings.key}`,
        method: "post",
        url: action.resend,
        withCredentials: true,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
      .then(() => {
        setSettings({
          ...settings,
          stage: "2fa",
          submitStatus: "",
        })

        return false
      })
      .catch((error) => {
        console.log(error)
      })
  }
  function isSmsStage() {
    return settings.mfa_type == "sms"
  }
  function createContact() {
    axios
      .request({
        method: "post",
        url: action.registration,
        data: serialize(fields),
        withCredentials: true,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
      .then((response) => {
        if (response.data.token) {
          setSettings({
            ...settings,
            token: response.data.token,
          })
          window.location.href = response.data.login_url
        }

        if (response.data.redirect) {
          window.location.href = response.data.redirect
        }
      })
      .catch((error) => {
        requestErrorResult(error.response)
      })
  }

  function actionByStage(data) {
    switch (type) {
      case "registration":
        createContact()
        break
      case "login":
        login(data)
        break
    }
  }
  function handleSubmit(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    setIsLoading(true)
    actionByStage(data)
  }
  return (
    <Modal>
      <Form onSubmit={(e) => handleSubmit(e)} onKeyDown={(e) => keyPress(e)}>
        <Header>
          <Title>
            {(() => {
              switch (type) {
                case "registration":
                  return "Open Account"
                case "login":
                  return "Client Login"
                case "reset":
                  return "Reset Password"
                default: // Do nothing
              }
            })()}
          </Title>
          {type === "registration" ? (
            <TabList>
              <TabTitle
                className={tab === "personal" ? "active" : null}
                onClick={() => setTab("personal")}
              >
                Personal
              </TabTitle>
              <TabTitle
                className={tab === "business" ? "active" : null}
                onClick={() => setTab("business")}
              >
                Business
              </TabTitle>
            </TabList>
          ) : null}
          <Close type="button" onClick={() => setModal(null)}>
            <SvgClose />
          </Close>
        </Header>
        {(() => {
          switch (type) {
            case "registration":
              return (
                <Body>
                  <FieldSetWrap>
                    <FieldSet
                      className={
                        fields.full_name.length > 0
                          ? "fullwidth filled"
                          : "fullwidth"
                      }
                    >
                      <Input
                        autoFocus
                        type="text"
                        id="full_name"
                        required
                        name="full_name"
                        onChange={(e) => updFields(e)}
                        value={fields.full_name}
                      />
                      <Label htmlFor="full_name">Full name</Label>
                      {errors.full_name ? (
                        <InputError>{errors.full_name}</InputError>
                      ) : null}
                    </FieldSet>
                  </FieldSetWrap>
                  <FieldSetWrap>
                    <FieldSet
                      className={fields.email.length > 0 ? "filled" : null}
                    >
                      <Input
                        type="email"
                        id="email_modal"
                        required
                        name="email"
                        onChange={(e) => updFields(e)}
                        value={fields.email}
                        key="email_modal"
                      />
                      <Label htmlFor="email_modal">Email</Label>
                      {errors.email ? (
                        <InputError>{errors.email}</InputError>
                      ) : null}
                    </FieldSet>
                    <FieldSet
                      className={fields.phone.length > 0 ? "filled" : ""}
                    >
                      <PhoneInput
                        international={true}
                        withCountryCallingCode={true}
                        rules={{ required: true }}
                        defaultCountry={country}
                        value={fields.fullphone}
                        onChange={handleUpdPhone}
                        name="phone"
                      />

                      {/* <PhoneHiddenInput
                        type="text"
                        id="phone"
                        name="phone"
                        required
                        defaultValue={fields.phone}
                      />
                      <Input
                        type="hidden"
                        id="country_code"
                        name="country_code"
                        required
                        value={fields.country_code}
                      /> */}
                      {errors.phone ? (
                        <InputError>{errors.phone}</InputError>
                      ) : null}
                      {errors.country_code ? (
                        <InputError>{errors.country_code}</InputError>
                      ) : null}
                    </FieldSet>
                  </FieldSetWrap>
                  {tab === "business" ? (
                    <FieldSet
                      className={
                        fields.company.length > 0
                          ? "fullwidth filled"
                          : "fullwidth"
                      }
                    >
                      <Input
                        type="text"
                        id="company"
                        required
                        name="company"
                        onChange={(e) => updFields(e)}
                        value={fields.company}
                      />
                      <Label htmlFor="company">Company Name</Label>
                    </FieldSet>
                  ) : null}
                </Body>
              )
            case "login":
              return (
                <Body>
                  {(() => {
                    switch (settings.mfa_type) {
                      case "sms":
                      case "email":
                        return (
                          <React.Fragment>
                            {isSmsStage() ? (
                              <Note>
                                Enter the code from the SMS we've sent you:
                              </Note>
                            ) : (
                              <Note>
                                Enter the code from the email we've sent you:
                              </Note>
                            )}
                            <FieldSetWrap className="field-code">
                              <FieldSet className="filled code">
                                <Input
                                  autoFocus
                                  type="text"
                                  id="code"
                                  required
                                  name="code"
                                />
                                {isSmsStage() ? (
                                  <Label htmlFor="code">Code from SMS</Label>
                                ) : (
                                  <Label htmlFor="code">Code from email</Label>
                                )}
                                {settings.codeInvalid ? (
                                  <InputError>Invalid code</InputError>
                                ) : null}
                              </FieldSet>
                              <Countdown
                                date={Date.now() + 59000}
                                key={key}
                                renderer={renderer}
                              />
                            </FieldSetWrap>
                          </React.Fragment>
                        )
                      case "totp":
                        return (
                          <React.Fragment>
                            <Note>
                              Enter the code generated by Google Authenticator
                              to proceed:
                            </Note>
                            <FieldSetWrap className="field-code">
                              <FieldSet className="filled code">
                                <Input
                                  autoFocus
                                  type="text"
                                  id="code"
                                  required
                                  name="code"
                                />
                                <Label htmlFor="code">6-digit code</Label>
                                {settings.codeInvalid ? (
                                  <InputError>
                                    Code is incorrect or expired. Please enter
                                    new code.
                                  </InputError>
                                ) : null}
                              </FieldSet>
                            </FieldSetWrap>
                          </React.Fragment>
                        )
                      default:
                        return (
                          <FieldSetWrap>
                            <FieldSet
                              className={
                                fields.login.length > 0 ? "filled" : null
                              }
                            >
                              <Input
                                autoFocus
                                type="text"
                                id="login"
                                required
                                name="login"
                                onChange={(e) => updFields(e)}
                                value={fields.login}
                              />
                              <Label htmlFor="login">Login</Label>
                              {errors.login ? (
                                <InputError>{errors.login}</InputError>
                              ) : null}
                            </FieldSet>
                            <FieldSet
                              className={
                                fields.password.length > 0 ? "filled" : null
                              }
                            >
                              <Input
                                type="password"
                                id="password"
                                required
                                name="password"
                                onChange={(e) => updFields(e)}
                                value={fields.password}
                              />
                              <Label htmlFor="password">Password</Label>
                              {errors.password ? (
                                <InputError>{errors.password}</InputError>
                              ) : null}
                              <Reset href={action.password_recovery}>
                                Forgot password?
                              </Reset>
                            </FieldSet>
                          </FieldSetWrap>
                        )
                    }
                  })()}
                </Body>
              )
            default: // Do nothing
          }
        })()}
        {errors.non_field_errors ? (
          <Body>
            <Error>{errors.non_field_errors}</Error>
          </Body>
        ) : null}
        {settings.formErrorText ? (
          <Body>
            <Error>{settings.formErrorText}</Error>
          </Body>
        ) : null}
        {(() => {
          switch (type) {
            case "registration":
              return (
                <Footer>
                  <Submit type="submit" ref={mainSubmit}>
                    Open Account
                  </Submit>
                  <FooterText>
                    Already have an account?{" "}
                    {/* <LoginLink onClick={() => switchType("login")}>
                      Log&nbsp;in
                    </LoginLink> */}
                    <LoginLink href="/clientsarea/accounts/login/">
                      Log&nbsp;in
                    </LoginLink>
                  </FooterText>
                </Footer>
              )
            case "login":
              return (
                <Footer>
                  {!settings.mfa_type ? (
                    <React.Fragment>
                      <Submit type="submit" ref={mainSubmit}>
                        Login
                      </Submit>
                      <FooterText>
                        Don’t have an account yet?{" "}
                        <LoginLink onClick={() => switchType("registration")}>
                          Open it
                        </LoginLink>
                      </FooterText>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Submit type="submit" ref={mainSubmit}>
                        Send
                      </Submit>
                    </React.Fragment>
                  )}
                </Footer>
              )
            default: // Do nothing
          }
        })()}
      </Form>
      {isLoading ? <Loading /> : null}
    </Modal>
  )
}

export default FormModal
