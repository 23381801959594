import React, { useState, Fragment } from "react";
import styled, { keyframes } from "styled-components";
import Device from "device";
import Media from 'react-media';

import Container from "components/Container";

const Wrap = styled.section`
  width: 100%;
  background: #fff;
  padding: 100px 0 60px;
  position: relative;
  z-index: 2;

  @media ${Device.laptop} {
    padding: 120px 0;
    min-height: 100vh;
  }
`;
const Card = styled.div`
  width: 320px;
  text-align: center;
  padding-bottom: 30px;

  @media ${Device.tablet} {
    text-align: left;

    padding-bottom: 40px;
  }
`;
const levi = keyframes`
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
`;
const CardImg = styled.img`
  max-width: 100%;
  animation-name: ${levi}
  animation-duration: 5s;
  animation-iteration-count: infinite;
`;
const NavWrap = styled.div`
  position: relative;
  width: 100%;
`;
const Nav = styled.nav`
  top: 0
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  @media ${Device.laptop} {
    position: relative;
    margin: 0 0 48px 0;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    height: auto;
  }
`;
const NavItem = styled.h3`
  cursor: pointer;
  color: rgba(0, 35, 125, 0.2);
  font-size: 32pxpx;
  font-weight: normal;
  line-height: 32px;
  margin: 0;
  position: relative;
  display: block;
  width: 100%;
  margin: 10px 0;
  text-align: center;

  @media ${Device.laptop} {
    font-size: 56px;
    line-height: 70px;
    margin: 0 64px 0 0;
    display: inline-block;
    width: auto;
    text-align: left;
  }

  &.active {
    color: ${props => props.theme.color.blue};
  }
  &[disabled] {
    display: none;
    span {
      position: relative;
      &:after {
        content: "soon";
        position: absolute;
        top: -10px;
        right: -20px;
        color: ${props => props.theme.color.blue};
        font-size: 12px;
        line-height: 12px;
        font-weight: 800;
      }
    }
  }
`;
const CurrentTab = styled.div`
  font-size: 36px;
  color: ${props => props.theme.color.blue};
  position: relative;
  display: inline-block;

  @media ${Device.laptop} {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -20px;
    width: 0;
    height: 0;
    border: 6px solid rgba(255, 255, 255, 0);
    border-top: 6px solid ${props => props.theme.color.blue};
    margin-top: -3px;
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
`;
const Note = styled.p`
  color: ${props => props.theme.color.blue};
`;
const Table = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;
const Row = styled.div`
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const HeaderRow = styled(Row)`
  font-weight: 800;

  @media ${Device.laptop} {
    flex-wrap: nowrap;
    border-bottom: 1px solid ${props => props.theme.color.blue};
  }
`;
const Col = styled.div`
  width: 100%;
  margin: 0;
  padding: 15px 0;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid rgba(0, 35, 125, 0.2);
  display: flex;
  justify-content: space-between;

  @media ${Device.laptop} {
    margin: 0 0 0 30px;
  }

  &:nth-child(1) {
    width: 100%;
    border-bottom: none;

    @media ${Device.laptop} {
      flex: 1;
      margin: 0;
      width: auto;
      border-bottom: 1px solid rgba(0, 35, 125, 0.2);
    }
  }
  &:nth-child(2),
  &:nth-child(3) {
    @media ${Device.laptop} {
      max-width: 25%;
    }
  }
  &:nth-child(4) {
    @media ${Device.laptop} {
      max-width: 30%;
    }
  }
`;
const HeaderCol = styled(Col)`
  border-bottom: 0;
  &:nth-child(1) {
    color: ${props => props.theme.color.blue};
    border-bottom: none;
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7){
    @media (max-width: 1023px) {
      display: none;
    }
  }
`;
const SubCol = styled.div`

`;
const HeaderRowMobile = styled.div`
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: bold;

  &.title-mobile {
    color: ${props => props.theme.color.blue};
  }
`;
const HeaderColMobile = styled.div`
  margin: 0;
  padding: 15px 0;
  font-size: 16px;
  line-height: 24px;

  .title-mobile & {
    font-size: 18px;
  }
`;

const tabs = [
  {
    id: "standard",
    name: "Standard Card",
    disabled: false
  },
  {
    id: "premium",
    name: "Premium Card",
    disabled: false
  }
];

const MobileTitlesTrAmount = () => {
  return (
    <Media queries={{
      tablet: '(max-width: 1023px)'
    }}>
      {matches => (
        matches.tablet ? (
          <Fragment>
            {/* <HeaderRowMobile className="title-mobile">
              <HeaderColMobile>Transaction Amount</HeaderColMobile>
            </HeaderRowMobile> */}
            <HeaderRowMobile>
              <HeaderColMobile>Maximum Per Transaction</HeaderColMobile>
            </HeaderRowMobile>
          </Fragment>
        ) : null
      )}
    </Media>
  )
};

const MobileTitlesDLimit = () => {
  return (
    <Media queries={{
      tablet: '(max-width: 1023px)'
    }}>
      {matches => (
        matches.tablet ? (
          <Fragment>
            {/* <HeaderRowMobile className="title-mobile">
              <HeaderColMobile>Daily Limit</HeaderColMobile>
            </HeaderRowMobile> */}
            <HeaderRowMobile>
              <HeaderColMobile>Daily Limit</HeaderColMobile>
              <HeaderColMobile>Daily Frequency</HeaderColMobile>
            </HeaderRowMobile>
          </Fragment>
        ) : null
      )}
    </Media>
  )
};

const MobileTitles30Limit = () => {
  return (
    <Media queries={{
      tablet: '(max-width: 1023px)'
    }}>
      {matches => (
        matches.tablet ? (
          <Fragment>
            {/* <HeaderRowMobile className="title-mobile">
              <HeaderColMobile>30-Day Limit</HeaderColMobile>
            </HeaderRowMobile> */}
            <HeaderRowMobile>
              <HeaderColMobile>30 day Limit</HeaderColMobile>
              <HeaderColMobile>30 day Frequency</HeaderColMobile>
              {/* <HeaderColMobile>Period 1</HeaderColMobile> */}
            </HeaderRowMobile>
          </Fragment>
        ) : null
      )}
    </Media>
  )
};

const Limits = () => {
  const [tab, setTab] = useState("standard");
  const [tabOpen, setTabOpen] = useState(false);
  const [tabName, setTabName] = useState("Standard Card");

  function handleUpdateTab(e) {
    if (e.disabled) {
      setTabOpen(false);
      return;
    }
    setTab(e.id);
    setTabName(e.name);
    setTabOpen(false);
  }
  function handleTabOpen(e) {
    setTabOpen(tabOpen ? false : true);
  }

  return (
    <Wrap id="limits">
      <Container>
      <Card>
          <CardImg
            src="/i/intro/gbxp-card-intro.png"
            srcSet="/i/intro/gbxp-card-intro@2x.png 2x"
            alt="Card"
          />
        </Card>
        <NavWrap>
          <CurrentTab onClick={() => handleTabOpen()}>{tabName}</CurrentTab>
          <Nav className={tabOpen ? "active" : null}>
            {Object.keys(tabs).map(key => (
              <NavItem
                key={key}
                onClick={() => handleUpdateTab(tabs[key])}
                className={tab === tabs[key].id ? "active" : null}
                disabled={tabs[key].disabled}
              >
                <span>{tabs[key].name}</span>
              </NavItem>
            ))}
          </Nav>
        </NavWrap>

        <Table>
          {/* <HeaderRow>
            <HeaderCol></HeaderCol>
            <HeaderCol>Transaction Amount</HeaderCol>
            <HeaderCol>Daily Limit</HeaderCol>
            <HeaderCol>30-Day Limit</HeaderCol>
          </HeaderRow> */}
          <HeaderRow>
            <HeaderCol>Transaction Type</HeaderCol>
            <HeaderCol>Maximum Per Transaction</HeaderCol>
            <HeaderCol>
              <SubCol>Daily Limit</SubCol>
              <SubCol>Daily Frequency</SubCol>
            </HeaderCol>
            <HeaderCol>
              <SubCol>30 day Limit</SubCol>
              <SubCol>30 day Frequency</SubCol>
              {/* <SubCol>Period (days)</SubCol> */}
            </HeaderCol>
          </HeaderRow>
          <Row>
            <MobileTitlesTrAmount />
            <Col>Cash Withdrawal</Col>
            <Col>
              {
                {
                  standard: "500.00",
                  premium: "1,000.00"
                }[tab]
              }
            </Col>
            <MobileTitlesDLimit />
            <Col>
              <SubCol>
                {
                  {
                    standard: "1,500.00",
                    premium: "2,000.00"
                  }[tab]
                }
              </SubCol>
              <SubCol>
                {
                  {
                    standard: "5",
                    premium: "10"
                  }[tab]
                }
              </SubCol>
            </Col>
            <MobileTitles30Limit />
            <Col>
              <SubCol>
                {
                  {
                    standard: "4,000.00",
                    premium: "5,000.00"
                  }[tab]
                }
              </SubCol> 
              <SubCol>
                {
                  {
                    standard: "30",
                    premium: "50"
                  }[tab]
                }
              </SubCol>  
              {/* <SubCol>
                {
                  {
                    standard: "30",
                    premium: "30"
                  }[tab]
                }
              </SubCol>    */}
            </Col>
          </Row>
          <Row>
            <MobileTitlesTrAmount />
            <Col>POS</Col>
            <Col>
              {
                {
                  standard: "7,000.00",
                  premium: "100,000.00"
                }[tab]
              }
            </Col>
            <MobileTitlesDLimit />
            <Col>
              <SubCol>
                {
                  {
                    standard: "7,000.00",
                    premium: "200,000.00"
                  }[tab]
                }
              </SubCol>
              <SubCol>
                {
                  {
                    standard: "250",
                    premium: "250"
                  }[tab]
                }
              </SubCol>
            </Col>
            <MobileTitles30Limit />
            <Col>
              <SubCol>
                {
                  {
                    standard: "15,000.00",
                    premium: "500,000.00"
                  }[tab]
                }
              </SubCol> 
              <SubCol>
                {
                  {
                    standard: "600",
                    premium: "3,000"
                  }[tab]
                }
              </SubCol>  
              {/* <SubCol>
                {
                  {
                    standard: "30",
                    premium: "30"
                  }[tab]
                }
              </SubCol>     */}
            </Col>
          </Row>
        </Table>
        <Note>
          NOTE The limits are denominated in the billing currency of the card to which they apply
        </Note>
      </Container>
    </Wrap>
  );
};

export default Limits;
