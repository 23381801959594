import React, { useContext } from "react"
import styled, { css } from "styled-components"
import Device from "device"

import AppContext from "contexts/AppContext"

import Container from "components/Container"
import ButtonStyle from "components/ButtonStyle"
import FormModal from "components/FormModal"

import { ReactComponent as SvgX } from "assets/x-card.svg"

import { ReactComponent as SvgCheck } from "assets/prepaid/icon-feather-check-square.svg"
import { ReactComponent as SvgPhone } from "assets/prepaid/icon-feather-phone.svg"
import { ReactComponent as SvgNo } from "assets/prepaid/icon-feather-no.svg"
import { ReactComponent as SvgCard } from "assets/prepaid/icon-feather-credit-card.svg"
import { ReactComponent as SvgArrow } from "assets/prepaid/icon-feather-arrow-right-circle.svg"
import { ReactComponent as SvgWifi } from "assets/prepaid/icon-feather-wifi.svg"
import { ReactComponent as SvgTarget } from "assets/prepaid/icon-feather-target.svg"
import { ReactComponent as SvgShield } from "assets/prepaid/icon-feather-shield.svg"
import { ReactComponent as SvgCross } from "assets/prepaid/icon-feather-x-square.svg"

const PrepaidCardWrap = styled.section`
  width: 100%;
  padding: 100px 0 60px 0;
  background-color: ${(props) => props.theme.color.blue};
  color: #fff;
  min-height: 100vh;
  z-index: 2;

  @media ${Device.tablet} {
    padding: 120px 0;
  }
  @media ${Device.laptopM} {
    padding: 240px 0;
  }

  a {
    color: inherit;
  }
  .container {
    padding: 0;

    @media ${Device.tablet} {
      padding: 0 15px;
    }
  }
`
const Left = styled.div`
  overflow: hidden;
  max-width: 100%;
  padding: 0 15px;

  @media ${Device.tablet} {
    max-width: 370px;
    position: sticky;
    z-index: 1;
    top: 200px;
    left: 0;
    overflow: visible;
    padding: 0;
  }
`
const Right = styled.div`
  position: relative;
  z-index: 2;
  flex: 1;
  padding: 0 15px;

  @media ${Device.tablet} {
    padding: 0 40px 0 150px;
  }
`
const CardTitle = styled.h2`
  color: #fff;
  position: relative;
  z-index: 2;
`
const Xcard = styled(SvgX)`
  display: none;

  @media ${Device.tablet} {
    display: block;
    position: absolute;
    top: 50%;
    height: 2160px;
    margin-top: -1080px;
    right: -546px;
    pointer-events: none;
    z-index: 0;
  }
`
const CardWrap = styled.div`
  position: relative;
`
const Card = styled.img`
  position: relative;
  z-index: 2;
  width: 370px;
  max-width: 100%;

  @media ${Device.tablet} {
    left: 0 !important;
  }
`

const Title = styled.h3`
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  padding: 30px 0 40px 0;
  position: sticky;
  top: 80px;
  bottom: 0;
  z-index: 3;
  background: ${(props) => props.theme.color.blue};

  @media ${Device.tablet} {
    position: static;
    background: none;
    padding: 0;
    margin: 35px 0 50px 0;
  }
`
const SubTitle = styled.h4`
  margin: 10px 0 15px 0;
`
const Section = styled.section`
  width: 100%;
  padding: 0;
  margin: 0;

  @media ${Device.tablet} {
    margin: 0 0 200px 0;
  }
`
const Ol = styled.ol`
  padding: 0;
  margin: 0 0 50px 0;
`
const Ul = styled(Ol)``
const Li = styled.li`
  margin: 0 0 10px 0;
  padding: 0 0 0 0;
  position: relative;
  list-style-position: inside;

  &:lastchild {
    margin-bottom: 0;
  }
`

const IconCss = css`
  height: 20px;
  fill: #fff;
  stroke: #fff;
  opacity: 0.4;
`
const IconCheck = styled(SvgCheck)`
  ${IconCss}
`
const IconPhone = styled(SvgPhone)`
  ${IconCss}
`
const IconNo = styled(SvgNo)`
  ${IconCss}
`
const IconCard = styled(SvgCard)`
  ${IconCss}
`
const IconArrow = styled(SvgArrow)`
  ${IconCss}
`
const IconWifi = styled(SvgWifi)`
  ${IconCss}
`
const IconTarget = styled(SvgTarget)`
  ${IconCss}
`
const IconShield = styled(SvgShield)`
  ${IconCss}
`
const IconCross = styled(SvgCross)`
  ${IconCss}
`

const Footer = styled.footer`
  margin-top: 20px;

  @media ${Device.tablet} {
    margin-top: 0;
  }
`
const FooterText = styled.h2`
  color: #fff;
`
const ToAccount = styled.a`
  ${ButtonStyle}
`
const Open = styled.button`
  ${ButtonStyle}
`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${Device.tablet} {
    justify-content: space-between;
    align-items: stretch;
  }
`
const GridItem = styled.div`
  width: 100%;
  margin: 0 0 30px 0;

  @media ${Device.tablet} {
    max-width: 45%;
    padding: 0 30px 0 0;
  }
`

const PrepaidCard = () => {
  const { setModal, user } = useContext(AppContext)

  return (
    <PrepaidCardWrap id="prepaid">
      <Container className="align-start container">
        <Left>
          <CardTitle>Prepaid Card</CardTitle>
          <CardWrap>
            <Card
              src="i/prepaid/gbxp-card-front.png"
              srcSet="i/prepaid/gbxp-card-front@2x.png 2x"
              alt="Prepaid Card"
            />
            <Xcard />
          </CardWrap>
        </Left>
        <Right>
          <Section>
            <Title>Enjoy your card at home and abroad</Title>
            <Grid>
              <GridItem>
                <IconNo />
                <SubTitle>No payment fees</SubTitle>
                <p>
                  Purchase whatever goods or services you require with zero
                  charge<br></br>
                  <a href="/fees">More</a>
                </p>
              </GridItem>
              <GridItem>
                <IconCard />
                <SubTitle>Fixed ATM fee</SubTitle>
                <p>
                  The fee is flat so you know exactly you pay when taking funds
                  from a cash machine
                </p>
              </GridItem>
              <GridItem>
                <IconArrow />
                <SubTitle>Withdrawals in local money</SubTitle>
                <p>Get local cash in any part of the world</p>
              </GridItem>
              <GridItem>
                <IconCross />
                <SubTitle>Generous limits</SubTitle>
                <p>
                  Choose between a Standard and Premium card according to your
                  needs.<br></br>
                  <a href="/cardlimits">More</a>
                </p>
              </GridItem>
            </Grid>
          </Section>
          <Section>
            <Title>Secure, cutting edge technology to make payments</Title>
            <Grid>
              <GridItem>
                <IconWifi />
                <SubTitle>Contactless payments</SubTitle>
                <p>Make handy purchases with contactless card payments</p>
              </GridItem>
              <GridItem>
                <IconTarget />
                <SubTitle>Track your finance</SubTitle>
                <p>Check and top up your card balance</p>
              </GridItem>
              <GridItem>
                <IconShield />
                <SubTitle>Secure payments</SubTitle>
                <p>The 3-D Secure® technology ensures transaction safety</p>
              </GridItem>
            </Grid>
          </Section>
          <Section>
            <Title>How to use?</Title>
            <div>
              <IconCheck />
              <SubTitle>Activate your card</SubTitle>
              <Ol>
                <Li>
                  Log into your client account on{" "}
                  {user.isAuthenticated && user.isAuthenticated !== "false" ? (
                    <a href="/clientsarea/account/">gbxp.uk</a>
                  ) : (
                    <a
                      href="/clientsarea/accounts/login/"
                      // onClick={() => setModal(<FormModal />)}
                    >
                      gbxp.uk
                    </a>
                  )}
                </Li>
                <Li>Click “Activate” – find your 4 digit PIN number</Li>
                <Li>Sign the back of your card</Li>
              </Ol>
            </div>
            <div>
              <IconPhone />
              <SubTitle>24/7 support</SubTitle>
              <Ul>
                <Li>
                  Call us at{" "}
                  <a href="tel:+442035190090">
                    +44&nbsp;2035&nbsp;190&nbsp;090
                  </a>
                </Li>
                <Li>
                  Write us at <a href="mailto:info@gbxp.uk">info@gbxp.uk</a>
                </Li>
                <Li>
                  Lost a card?{" "}
                  {user.isAuthenticated && user.isAuthenticated !== "false" ? (
                    <a href="/clientsarea/account/">My account</a>
                  ) : (
                    <a
                      href="/clientsarea/accounts/login/"
                      // onClick={() => setModal(<FormModal />)}
                    >
                      Log in
                    </a>
                  )}{" "}
                  and block it manually by clicking “Lost card”
                </Li>
              </Ul>
            </div>
          </Section>
          <Footer>
            <FooterText>
              Get your card delivered <span>anywhere!</span>
            </FooterText>

            {user.isAuthenticated && user.isAuthenticated !== "false" ? (
              <ToAccount href="/clientsarea/account/">My account</ToAccount>
            ) : (
              <Open onClick={() => setModal(<FormModal />)}>
                Open an account
              </Open>
            )}
          </Footer>
        </Right>
      </Container>
    </PrepaidCardWrap>
  )
}

export default PrepaidCard
