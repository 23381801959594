import React, { Fragment } from "react";
import styled from "styled-components";

import Topline from "components/Topline";
import Limits from "components/Limits/Limits";
import Footer from "components/Footer";

const Main = styled.main`
  width: 100%;
`;

const LimitsPage = () => {
  return (
    <Fragment>
      <Topline />
      <Main>
        <Limits />
        <Footer />
      </Main>
    </Fragment>
  );
};
export default LimitsPage;
